import "./HmPage5.css";
import React from "react";
import img1 from "./Images/img1.svg";

const featureshm = [
  {
    title: "Order Processing",
    description:
      "Control the creation, processing, and tracking of orders. It offers real-time order status information, guarantees order accuracy, and automates order submission.",
    icon: "\uD83D\uDEE1️", // Shield emoji
  },
  {
    title: "Route Optimization",
    description:
      "helps identify the best shipping routes by taking into account variables like distance, traffic, and delivery windows. It speeds up deliveries and lowers transportation expenses.",
    icon: "\uD83D\uDC64", // User emoji
  },
  {
    title: "Shipment Tracking",
    description:
      "allows businesses and clients to track the location and status of shipments. It improves visibility and enables you to respond quickly to problems or delays.",
    icon: "\uD83D\uDCB2", // Dollar emoji
  },
  {
    title: "Billing and Invoicing",
    description:
      "include billing and invoicing tools for managing payment processing, creating invoices, and keeping track of transportation expenses.",
    icon: "\uD83D\uDD25", // Fire emoji
  },
  {
    title: "Security and Compliance",
    description:
      "maintain data security, safeguard private data, and follow rules and guidelines unique to your industry.",
    icon: "\uD83D\uDCC4", // Document emoji
  },
  {
    title: "Reporting and Analytics",
    description:
      "give you a thorough analysis of what is effective and what can require improvement. In just a few seconds, they enable users to generate personalised reports and dashboards that track, retrieve, and summarise key performance indicators (KPIs).",
    icon: "\uD83D\uDED1", // Warning emoji
  },
];

export default function HmPage5() {
  return (
    <section className="app-featuresHm">
      <h2 className="titleHm">Our  Esparse Logistics Solutions Feature </h2>
      <div className="features-containerHm">
        {featureshm.map((feature, index) => (
          <div key={index} className="feature-cardHm">
            <div className="iconHm">{feature.icon}</div>
            <h3 className="feature-titleHm">{feature.title}</h3>
            <img src={img1} alt="" />
            <p className="feature-descriptionHm">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
