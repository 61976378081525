import React from "react";
import {
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaTwitter,
} from "react-icons/fa";
import img1 from "./images/image1.png";
import img2 from "./images/image2.png";
import img3 from "./images/image.png";
import "./IndustryForm.css"; // Import the CSS file
import axios from 'axios';
import { useState } from 'react';
import { FiBook, FiUser } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import { FaRegComment } from "react-icons/fa6";
import { MdDownload, MdOutlineMail } from "react-icons/md";
const IndustryForm = () => {
  // const [loading, setLoading] = useState(false); // To show loading state
  const [message, setMessage] = useState(""); // To show success or error message

  const [formData, setFormData] = useState({
         name: "",
         Mobile: "",
         Email: "",
         Subject: "",
         Comment: "",
       });
     
       const [loading, setLoading] = useState(false);
     
       const handleChange = (e) => {
         const { name, value } = e.target;
         setFormData((prevState) => ({
           ...prevState,
           [name]: value,
         }));
       };
     
       const handleSubmit = async (e) => {
         e.preventDefault();
         setLoading(true);
     
         try {
           const response = await fetch("https://esparsewebsitebackend.vercel.app/api/v1/CreatecontactDetails", {
             method: "POST",
             headers: {
               "Content-Type": "application/json",
             },
             body: JSON.stringify(formData),
           });
     
           if (!response.ok) {
             throw new Error("Something went wrong");
           }
     
           const result = await response.json();
           alert("Form submitted successfully!");
         } catch (error) {
           console.error("Error submitting form:", error);
           alert("Failed to submit the form.");
         } finally {
           setLoading(false);
         }
       };
  return (
    <div className="contact-container">
      <div className="contact-box">
        <div className="form-section">
          <h2 className="subtitle"> \ Get In Touch \ </h2>
          <h1 className="title">Hey! Let's Talk</h1>


                <form
      className="w-[615px] h-[680px] p-[54px_34px_36px_34px] flex flex-col gap-[32px] rounded-[12px] border-2 bg-white"
      onSubmit={handleSubmit}
    >
      <div className="flex gap-[32px] items-center justify-center">
        <div className="w-[50%] h-[73px]">
          <label className="text-[14px] font-bold leading-[21px] text-left">
            Your Name <span className="text-[#E43F52]">*</span>
          </label>
          <div className="relative w-full my-3">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FiUser className="text-xl" />
            </span>
            <input
              type="text"
              name="name"
              placeholder="Your Name:"
              value={formData.name}
              onChange={handleChange}
              className="border-2 w-full border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        <div className="w-[50%] h-[73px]">
          <label className="text-[14px] font-bold leading-[21px] text-left">
            Your Mobile <span className="text-[#E43F52]">*</span>
          </label>
          <div className="relative w-full my-3">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <BsTelephone className="text-lg" />
            </span>
            <input
              type="Mobile"
              name="Mobile"
              placeholder="Your Mobile:"
              value={formData.Mobile}
              onChange={handleChange}
              className="border-2 w-full border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>
      </div>

      <div className="w-[100%] h-auto flex flex-col gap-[22px]">
        <div className="w-full h-[73px]">
          <label className="text-[14px] font-bold leading-[21px] text-left">
            Your Email <span className="text-[#E43F52]">*</span>
          </label>
          <div className="relative w-full my-3">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <MdOutlineMail className="text-xl" />
            </span>
            <input
              type="Email"
              name="Email"
              placeholder="Your Email:"
              value={formData.Email}
              onChange={handleChange}
              className="border-2 w-full border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        <div className="w-full h-[73px]">
          <label className="text-[14px] font-bold leading-[21px] text-left">
            Subject
          </label>
          <div className="relative w-full my-3">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FiBook className="text-lg" />
            </span>
            <input
              type="Subject"
              name="Subject"
              placeholder="Subject"
              value={formData.Subject}
              onChange={handleChange}
              className="border-2 w-full border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div className="w-full h-[73px]">
          <label className="text-[14px] font-bold leading-[21px] text-left">
            Comments
          </label>
          <div className="relative w-full my-3">
            <span className="absolute inset-y-0 left-0 top-3 pl-3">
              <FaRegComment className="text-lg" />
            </span>
            <textarea
              rows="Comment"
              name="Comment"
              placeholder="Your Comments"
              value={formData.Comment}
              onChange={handleChange}
              className="border-2 w-full border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="mt-5">
            <button
              type="submit"
              className="w-full h-12 px-6 rounded-lg border border-solid border-[#2F55D4] shadow-md bg-[#2F55D4] text-white font-medium"
              disabled={loading}
            >
              {loading ? "Sending..." : "Send"}
            </button>
          </div>
        </div>
      </div>
    </form>
        </div>
      </div>
      {/* Right Side - Contact Info */}
      <div className="info-sections">

        <div className="info-item">
        <img src={img2} alt="" />
          <a href="mailto:info@sparsematrix.co.in">info@sparsematrix.co.in</a>
        </div>
        <div className="info-item">
        <img src={img3} alt="" />
          <a href="https://www.sparsematrix.co.in">www.sparsematrix.co.in</a>
        </div>

        {/* Social Media Links */}
        <div className="social-section">
          <h3>Follow us</h3>
          <div className="social-icons">
            <a href="#" className="social-btn">
              <FaLinkedin />
            </a>
            <a href="#" className="social-btn">
              <FaInstagram />
            </a>
            <a href="#" className="social-btn">
              <FaFacebook />
            </a>
            <a href="#" className="social-btn">
              <FaTwitter />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryForm;
