import React from "react";
import "./HmPage2.css";
import img1 from "./Images/img1.svg";

function HmPage2() {
  return (
    <div className="Hm-pg2-Container">
      <div className="Hm-pg2-img1">
        <img src={img1} alt="" />
      </div>
      <div className="Hm-pg2-txt">
        <div className="Hm-pg2-txt-head">About Esparse Logistic Solutions  </div>
        <div>
        The software solutions offered by Esparse Logistics are specialised digital tools made to optimise and expedite the many supply chain management, transportation, warehousing, and distribution activities. These technologies increase operational efficiency, lower costs, and boost customer happiness by automating operations and offering real-time analytics.
        </div>
      </div>
    </div>
  );
}

export default HmPage2;