import React from 'react'
import HmPage1 from './Page1/HmPage1';
import HmPage2 from './Page2/HmPage2';
import FeatureSectionHm from './Page3/HmPage3';
import HmPage4 from './Page4/HmPage4';
import HmPage5 from './Page5/HmPage5';
import HmPage6 from './Page6/HmPage6';
import ContactForm from '../BoraGoCarpooling/Page8/Page8';
// import Footer from '../Footer/Footer';
export const EsparseLogisticSolutions = () => {
  return (
    <div className='Homepg'>
       <HmPage1/>
       <HmPage2/>
       <FeatureSectionHm/>
       <HmPage4/>
       <HmPage5/>
       <HmPage6/>
       <ContactForm/>
       {/* <Footer/> */}
   
    </div>
  )
}
