import "./Page4.css";
import img2 from "./images/img2.jpeg";
import React from "react";

export default function Page4() {
  return (
    <section className="what-we-do">
      <div className="container">
        <div className="image-container">
          <img src={img2} alt="Team Working Together" />
        </div>
        <div className="content">
          <span className="subtitle">
            <span role="img" aria-label="rocket">🚀</span> What We Do \
          </span>
          <h2 className="title">Why Should We Carpool?</h2>
          <p className="description">
            Carpooling helps reduce traffic congestion, lowers emissions, and saves costs.
            It's an eco-friendly and budget-friendly way to travel while building community connections.
          </p>

          <div className="info-cards">
            <div className="card">
              <h3>
                <span role="img" aria-label="earth">🌍</span> Our Vision
              </h3>
              <p>To create a sustainable and efficient transport ecosystem.</p>
            </div>
            <div className="card">
              <h3>
                <span role="img" aria-label="target">🎯</span> Our Goal
              </h3>
              <p>Encourage people to share rides and contribute to a greener planet.</p>
            </div>
          </div>

          <button className="view-more">View More</button>
        </div>
      </div>
    </section>
  );
}
