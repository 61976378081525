import React from "react";
import "./Page1.css";
import img1 from "./Images/img1.svg";
import img2 from "./Images/img2.svg";
function Page1() {
  return (
    <div className="pg1-Container">
      <div className="pg1-txtcon">
        <div className="Pg1-img2">
          <img src={img2} alt="" />
        </div>
        <div className="pg1-txt">
          <div className="Pg1-txt2">
            Bora Go Carpooling – Smarter, Greener, and More Affordable Travel
          </div>

          <div className="Pg1-buts">
            <button className="Download-Now">Download Now</button>
          </div>
        </div>
      </div>

      <div className="Pg1-img">
        <img src={img1} alt="" />
      </div>
    </div>
  );
}

export default Page1;
