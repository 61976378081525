import React from "react";
import "./HmPage6.css";
import img1 from "./Images/img1.svg";
import img2 from "./Images/img2.svg";
import img3 from "./Images/img3.svg";
import img4 from "./Images/img4.svg";

function HmPage6() {
  return (
    <div className="pg6-ContainerHm">
      <div className="pg6-txtHm">
        <div className="pg6-txt-headHm">Who Can Use Esparse Logistics Solutions?</div>
        <div className="pg6-divsHm">
          <div className="pg6-div1Hm">
            <img src={img1} alt="" />
            <div className="pg6-div-hHm">Retail and E-commerce</div>
            <div className="pg6-div-pHm">
            Logistics software streamlines business processes like order fulfillment, inventory management, and timely deliveries, ensuring accurate tracking and management of goods from warehouses to customers.
            </div>
          </div>
          <div className="pg6-div2Hm">
            <img src={img2} alt="" />
            <div className="pg6-div-hHm">Healthcare & Pharmaceuticals</div>
            <div className="pg6-div-pHm">
            In the medical sector, logistics software ensures timely and accurate delivery of sensitive supplies, monitors environmental conditions during transit, and efficiently maintains inventory.
            </div>
          </div>
          <div className="pg6-div3Hm">
            <img src={img3} alt="" />
            <div className="pg6-div-hHm">Third-Party Logistics (3PL) Providers</div>
            <div className="pg6-div-pHm">
            Software that manages multiple clients' supply chains, transportation, and warehousing needs benefits companies providing logistics services, enhancing scalability, efficiency, and accurate reporting.
            </div>
          </div>
          <div className="pg6-div4Hm">
            <img src={img4} alt="" />
            <div className="pg6-div-hHm">Manufacturing</div>
            <div className="pg6-div-pHm">
            Manufacturers utilize logistics software to efficiently manage transportation, inventory, and supply chain operations, ensuring timely procurement of raw materials and product distribution.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HmPage6;
