import React from "react";
import "./HmPage3.css";
import img1 from "./Images/img1.svg";

const Hmfeature = [
  {
    title: "Enhanced Operational Efficiency",
    description:
      "Operations become more efficient when manual processes are automated since it speeds up workflows and lowers errors.",
    icon: "🛡️", // Shield emoji
  },
  {
    title: "Cost Reduction",
    description:
      "Operational costs can be greatly reduced by streamlining transportation, inventory levels, and routes.",
    icon: "👤", // User emoji
  },
  {
    title: "Improved Visibility and Control",
    description:
      "Proactive problem solving and data-driven decision-making are made possible by real-time tracking and analytics, which offer thorough oversight.",
    icon: "💲", // Dollar emoji
  },
  {
    title: "Better Customer Satisfaction",
    description:
      "The entire customer experience is improved by effective logistics operations, which guarantee on-time delivery and precise order fulfilment.",
    icon: "🔥", // Fire emoji
  },
];

export default function FeatureSectionHm() {
  return (
    <section className="feature-wrapperHm">
      <h2 className="section-headingHm">Benefits of Implementing Esparse  Logistics  Solutions</h2>
      <div className="feature-gridHm">
        {Hmfeature.map((item, index) => (
          <div key={index} className="feature-boxHm">
            <div className="feature-iconHm">{item.icon}</div>
            <h3 className="feature-headingHm">{item.title}</h3>
            <img src={img1} alt="" className="feature-imgHm" />
            <p className="feature-textHm">{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
