import About from "./Components/About";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import { Routes, Route } from "react-router-dom";
import WebDev from "./Components/Services/WebDev";
import AppDev from "./Components/Services/AppDev";
import AllIndustryERP from "./Components/Services/AllIndustryERP";
import HRMS from "./Components/Services/HRMS";
import CRM from "./Components/Services/CRM";
import ERP from "./Components/Services/ERP";
import CSDS from "./Components/Services/CSDS";
import Career from "./Components/Career";
import SingleCareer from "./Components/SingleCareer";
import Blog from "./Components/Blog";
import SingleBlog from "./Components/SingleBlog";
import Events from "./Components/Events";
import Contact from "./Components/Contact";
import Elearning from "./Components/Products/E-learning";
import NGO from "./Components/Products/NGO";
import Logistics from "./Components/Products/Logistics";
import AI from "./Components/Services/AI";
import DS from "./Components/Services/DS";
import DevOps from "./Components/Services/DevOps";
import ML from "./Components/Services/ML";
import SWservices from "./Components/Services/SWservices";
import UIUX from "./Components/Services/UIUX";
import BoraGoCarpooling from "./Components/BoraGoCarpooling/ourProducts/ourProducts";
import Industry from "./Components/IndustryMeetup/Industry";
import { EsparseLogisticSolutions } from "./Components/Homepg/Homepg";
function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/webdev" element={<WebDev />} />
        <Route path="/appdev" element={<AppDev />} />
        <Route path="/allindustrymanufacturingerps" element={<AllIndustryERP />} />
        <Route path="/hrms" element={<HRMS />} />
        <Route path="/crm" element={<CRM />} />
        <Route path="/erp" element={<ERP />} />
        <Route path="/csds" element={<CSDS />} />
        <Route path="/career" element={<Career />} />
        <Route path="/apply" element={<SingleCareer />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/read" element={<SingleBlog />} />
        <Route path="/events" element={<Events />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/e-learn" element={< Elearning/>} />
        <Route path="/ngo" element={< NGO/>} />
        <Route path="/logistics" element={< Logistics/>} />
        <Route path="/ai" element={< AI/>} />
        <Route path="/ds" element={< DS/>} />
        <Route path="/devops" element={< DevOps/>} />
        <Route path="/ml" element={< ML/>} />
        <Route path="/st" element={< SWservices/>} />
        <Route path="/uiux" element={< UIUX/>} />
        <Route path="/boragocarpooling" element={< BoraGoCarpooling/>} />
        <Route path="/our-services" element={< Industry/>} />
        <Route path="/esparse-logistic-solutions" element={< EsparseLogisticSolutions/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
