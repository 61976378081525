import React from "react";
import image1 from "../assets/productsNavbar/card1.png";
import image2 from "../assets/productsNavbar/card2.png";
import image3 from "../assets/productsNavbar/card3.png";
import icon1 from "../assets/homeServicesSection/icon3.png";
import icon2 from "../assets/homeServicesSection/icon1.png";
import icon3 from "../assets/homeServicesSection/icon2.png";
import image4 from "../Components/BoraGoCarpooling/Page1/Images/img1.svg";
import image5 from "../Components/BoraGoCarpooling/Page1/Images/mentor.jpg";
import arrow from "../assets/viewdetails.png";
import logistics from "../assets/logistics.png";
import { Link } from "react-router-dom";

const services = [
  {
    icon: icon1,
    image: image1,
    title: "Avinya E-learning Platform",
    desc: "Avinya E-learning is a platform for teachers to manage their online coaching. It offers tools for student management, course creation, and virtual classrooms. Sign up today to streamline your teaching process..",
    link: "/e-learn",
  },
  {
    icon: icon2,
    image: image2,
    title: "Avinya NGO app",
    desc: "Avinya NGO App is a mobile app for NGOs. It helps manage projects, volunteers, and donors efficiently. Download now to streamline your organization's operations.",
    link: "/ngo",
  },
  // {
  //     icon: icon2,
  //     image: image3,
  //     title: "Esparse Logistics Software",
  //     desc: 'sparse Logistics Software streamlines supply chains with real-time tracking, route optimization, and inventory control. Improve your logistics efficiency today.',
  //     link: '/logistics'

  // },
  {
    icon: icon3,
    image: image4,
    title: "Bora Go Carpooling",
    desc: "Save money, reduce traffic, and cut carbon emissions by sharing rides with trusted commuters. Join Bora Go and make every trip eco-friendly!",
    link: "/boragocarpooling",
  },
  {
    icon: icon3,
    image: image5,
    title: "Industry Mentor Meetup Program",
    desc: "The Industry Mentor Meetup Program by Esparse Matrix Solutions Private Limited is a premier initiative designed to connect aspiring professionals, students, and entrepreneurs with experienced industry mentors..",
    link: "/our-services",
  },
  {
    icon: icon3,
    image: logistics,
    title: "Esparse Logistic Solutions",
    desc: 'Esparse Logistic Solutions – "Navigating Logistics, Delivering Excellence." Our innovative platform optimizes supply chains, enhances real-time tracking, and ensures seamless transportation management. Experience efficiency and precision with Esparse today!',
    link: "/esparse-logistic-solutions",
  },
];
const ProductsNavbar = () => {
  return (
    <div className="w-full bg-white h-[390px] p-[24px] shadow-md absolute top-20 left-0 z-10 overflow-hidden">
      <div className="w-full h-full flex overflow-x-auto gap-6 px-[88px] scrollbar-hide">
        {services.map((s, index) => (
          <div
            key={index}
            className="w-[209px] min-w-[209px] h-[330px] shadow-md flex-shrink-0"
          >
            <img
              src={s.image}
              className="object-cover h-[150px] w-full rounded-t"
              alt="service"
            />
            <div className="bg-white rounded flex flex-col items-center gap-4 p-4 relative">
              <div className="w-[49px] h-[49px] p-[10px] rounded-full shadow-md bg-white absolute -top-6">
                <img src={s.icon} alt="icon" />
              </div>
              <div className="text-center flex flex-col gap-2 mt-6 text-black">
                <p className="text-[12px] font-bold">{s.title}</p>
                <p className="text-[0.7rem] font-normal">{s.desc}</p>
                <Link
                  to={s.link}
                  className="flex items-center text-[12px] gap-1 text-blue-500"
                >
                  <span>View Details</span>
                  <img src={arrow} alt="arrow" className="w-[9px]" />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsNavbar;
