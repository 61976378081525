import React from "react";
import "./HmPage1.css";
import img1 from "./Images/img1.svg";
import img2 from "./Images/img2.svg";
function HmPage1() {
  return (
    <div className="Hm-pg1-Container">
      <div className="Hm-pg1-txtcon">
        <div className="Hm-Pg1-img2">
          <img src={img2} alt="" />
        </div>
        <div className="Hm-pg1-txt">
          <div className="Hm-Pg1-txt2">
          Esparse Logistic Solutions – "Navigating Logistics, Delivering Excellence"
          </div>

          <div className="Hm-Pg1-buts">
            <button className="Hm-Download-Now">Download Now</button>
          </div>
        </div>
      </div>

      <div className="Hm-Pg1-img">
        <img src={img1} alt="" />
      </div>
    </div>
  );
}

export default HmPage1;
