import React from "react";
import "./Programhl.css";

const highlights = [
  {
    title: "Industry Expert Sessions",
    description: "Learn from leading professionals and domain experts.",
  },
  {
    title: "Career Growth Guidance",
    description:
      "Gain valuable insights into industry trends and career pathways.",
  },
  {
    title: "Networking Opportunities",
    description: "Expand your professional network with mentors and peers.",
  },
  {
    title: "Flexible Participation",
    description: "Attend meetups online or in person at designated locations.",
  },
  {
    title: "Interactive Learning",
    description: "Engage in Q&A sessions, panel discussions, and case studies.",
  },
  {
    title: "Enhance Employability",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
  },
];

const ProgramHighlights = () => {
  return (
    <section className="highlights-container">
      <h2 className="highlights-heading">Program Highlights</h2>
      <div className="highlights-grid">
        {highlights.map((item, index) => (
          <div key={index} className="highlight-card">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ProgramHighlights;
