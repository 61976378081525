import "./Page5.css";
import React from "react";
import img1 from "./Images/img1.svg";
const features = [
  {
    title: "Secure & Verified Rides",
    description:
      "Ride with trusted users through identity verification and in-app safety features.",
    icon: "\uD83D\uDEE1️", // Shield emoji
  },
  {
    title: "Smart Ride Matching",
    description:
      "Find the best carpool partners based on your route, schedule, and preferences.",
    icon: "\uD83D\uDC64", // User emoji
  },
  {
    title: "Cost Sharing Made Easy",
    description:
      "Split fuel and travel costs fairly with transparent digital payments.",
    icon: "\uD83D\uDCB2", // Dollar emoji
  },
  {
    title: "Eco-Friendly Travel",
    description:
      "Reduce traffic congestion and carbon emissions by sharing rides.",
    icon: "\uD83D\uDD25", // Fire emoji
  },
  {
    title: "In-App Chat & Notifications",
    description:
      "Communicate securely with co-riders and receive real-time updates about your rides.",
    icon: "\uD83D\uDCC4", // Document emoji
  },
  {
    title: "Emergency SOS & Support",
    description:
      "Dedicated support and an SOS feature for added safety during your journey.",
    icon: "\uD83D\uDED1", // Warning emoji
  },
];

export default function Page5() {
  return (
    <section className="app-features">
      <h2 className="title">Our App Feature</h2>
      <div className="features-container">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="icon">{feature.icon}</div>
            <h3 className="feature-title">{feature.title}</h3>
            <img src={img1} alt="" />
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
