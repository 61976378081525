import React from "react";
import "./Industry.css";

import img1 from "./img1.jpeg";

import ProgramHighlights from "./Programhl/Programhl";
import WhoShouldJoin from "./whojoin/whojoin";
import HowItWorks from "./howworks/howworks";
import Page8 from "../BoraGoCarpooling/Page8/Page8";
import IndustryForm from "./industryForm/IndustryForm";
function Industry() {
  return (
    <div className="industry-containers">
 
      <div className="industry-text">
        <div className="industry-head">Industry Mentor Meetup Program</div>
        <div>
          Bridging the Gap Between Aspiring Professionals and Industry Leaders
        </div>
      </div>
      <section className="industry-container">
        <div className="industry-wrapper">
          <div className="industry-image">
            <img src={img1} alt="Team Working Together" />
          </div>
          <div className="industry-content">
            <span className="industry-highlight">\ What We Do \</span>
            <h2 className="industry-heading">
              We Develop Product That People Love to Use.
            </h2>
            <p className="industry-description">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed, of a page when looking at its
              layout. The point of using Lorem Ipsum is that of a page when
              looking at its layout. The point of using Lorem Ipsum is thatLorem
              Ipsum is that it has a more-or-less normalLorem IpsumLorem Ipsum
              distribution of letters, as opposed
            </p>

            <div className="industry-features">
              <div className="industry-feature-item">
                <h3>Our Vision</h3>
                <p>It is a long established fact that</p>
              </div>
              <div className="industry-feature-item">
                <h3>Our Goal</h3>
                <p>It is a long established fact that</p>
              </div>
            </div>

          </div>
        </div>
      </section>
      <ProgramHighlights />
      <WhoShouldJoin />
      <HowItWorks />
      <div>
       <IndustryForm/>
      </div>
    

  
    </div>
  );
}

export default Industry;
