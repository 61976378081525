import React from "react";
import "./whojoin.css";

const joinCategories = [
  {
    title: "Students & Fresh Graduates",
    description: "Looking for career guidance and industry exposure.",
  },
  {
    title: "Working Professionals",
    description: "Seeking mentorship and career advancement opportunities.",
  },
  {
    title: "Entrepreneurs & Startups",
    description: "Eager to gain valuable insights from industry leaders.",
  },
];

const WhoShouldJoin = () => {
  return (
    <section className="join-container">
      <h2 className="join-heading">Who Should Join?</h2>
      <div className="join-grid">
        {joinCategories.map((item, index) => (
          <div key={index} className="join-card">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhoShouldJoin;
