import React from "react";
import "./Page7.css";
import img1 from "./images/img1.svg";
import img2 from "./images/img2.svg";
import img3 from "./images/img3.svg";
const benefits = [
  {
    title: "Reduce Traffic",
    description:
      "Road efficiency is increased and congestion is reduced when there are fewer vehicles on the road.",
    image: img1, // Replace with actual image URL
  },
  {
    title: "Build a Network",
    description:
      "Expand your social network, meet like-minded professionals, and strengthen relationships in the workplace or in the community.",
    image: img2,
  },
  {
    title: "Eco-Friendly",
    description:
      "Reduces air pollution and carbon emissions and helps create a cleaner, greener world.",
    image: img3,
  },
];

export default function Page7() {
  return (
    <section className="benefits-section">
      <h2 className="benefits-title">Why Should We Carpool?</h2>
      <div className="benefits-container">
        {benefits.map((benefit, index) => (
          <div key={index} className="benefit-card">
            <img
              src={benefit.image}
              alt={benefit.title}
              className="benefit-image"
            />
            <h3 className="benefit-heading">{benefit.title}</h3>
            <p className="benefit-description">{benefit.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
