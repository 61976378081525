import React from "react";
import "./Page2.css";
import img1 from "./Images/img1.svg";

function Page2() {
  return (
    <div className="pg2-Container">
      <div className="pg2-img1">
        <img src={img1} alt="" />
      </div>
      <div className="pg2-txt">
        <div className="pg2-txt-head">About Bora Go Carpooling </div>
        <div>
          Bora Go—Carpool is a smart and secure carpooling platform that
          connects commuters travelling in the same direction, allowing them to
          share rides, split costs, and reduce their carbon footprint. It offers
          a seamless way to find and share rides with verified users, making
          daily commutes more affordable, eco-friendly, and convenient. Whether
          you're a professional, student, or daily commuter, Bora Go helps you
          travel smarter while contributing to a greener planet.
        </div>
      </div>
    </div>
  );
}

export default Page2;
