import React from "react";
import "./Page3.css";
import img1 from "./Images/img1.svg";

const feature = [
  {
    title: "Sign Up & Set Your Profile",
    description:
      "Download the Bora Go app, register, complete your profile, and verify your identity for a secure experience.",
    icon: "🛡️", // Shield emoji
  },
  {
    title: "Post or find a ride, connect, and confirm.",
    description:
      "Enter your route, find a compatible carpool partner, securely chat with co-riders, and finalize your ride.",
    icon: "👤", // User emoji
  },
  {
    title: "Share the Journey",
    description:
      "Meet your co-travelers, enjoy a comfortable ride, and split the costs transparently",
    icon: "💲", // Dollar emoji
  },
  {
    title: "Pay & Review",
    description:
      "Make easy digital payments and rate your ride experience to ensure a trusted community.",
    icon: "🔥", // Fire emoji
  },
];

export default function FeatureSection() {
  return (
    <section className="feature-wrapper">
      <h2 className="section-heading">Our Boro Go App Process</h2>
      <div className="feature-grid">
        {feature.map((item, index) => (
          <div key={index} className="feature-box">
            <div className="feature-icon">{item.icon}</div>
            <h3 className="feature-heading">{item.title}</h3>
            <img src={img1} alt="" className="feature-img" />
            <p className="feature-text">{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
