import "./HmPage4.css";
import img2 from "./images/img2.jpeg";
import React from "react";

export default function HmPage4() {
  return (
    <section className="what-we-doHm">
      <div className="containerHm">
        <div className="image-containerHm">
          <img src={img2} alt="Team Working Together" />
        </div>
        <div className="contentHm">
          <h2 className="titleHm">Why Should Esparse Logistics Solutions?</h2>
          <p className="descriptionHm">
          logistics software is designed to enhance operational efficiency by automating various processes, thereby reducing manual errors and streamlining workflows.By choosing eSparse Matrix Solutions for your logistics needs, you can benefit from their expertise in software development and their commitment to providing tailored solutions that align with your business objectives. Their logistics software aims to improve efficiency, reduce operational costs, and enhance customer satisfaction.
          </p>

 

       
        </div>
      </div>
    </section>
  );
}