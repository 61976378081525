import React from "react";
import "./Page6.css";
import img1 from "./Images/img1.svg";
import img2 from "./Images/img2.svg";
import img3 from "./Images/img3.svg";
import img4 from "./Images/img4.svg";
function Page6() {
  return (
    <div className="pg6-Container">
      <div className="pg6-txt">
        <div className="pg6-txt-head">Our Boro Go App Process</div>
        <div className="pg6-divs">
          <div className="pg6-div1">
            <img src={img1} alt="" />
            <div className="pg6-div-h">Weekend Travelers</div>
            <div className="pg6-div-p">
              Share rides for weekend getaways and holiday trips with
              like-minded travelers.
            </div>
          </div>
          <div className="pg6-div2">
            <img src={img2} alt="" />
            <div className="pg6-div-h">Business Travelers</div>
            <div className="pg6-div-p">
              Reduce travel costs for work trips while networking with
              professionals on the go.
            </div>
          </div>
          <div className="pg6-div3">
            <img src={img3} alt="" />
            <div className="pg6-div-h">Tourists & Travelers</div>
            <div className="pg6-div-p">
              Solo travelers or groups looking for a budget-friendly and
              comfortable alternative to buses or trains.
            </div>
          </div>
          <div className="pg6-div4">
            <img src={img4} alt="" />
            <div className="pg6-div-h">Families & Groups</div>
            <div className="pg6-div-p">
              Families or friends heading to the same destination who want a
              convenient and shared travel option.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page6;
