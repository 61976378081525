import React from "react";
import "./howworks.css";

const steps = [
  {
    id: "01",
    title: "Register Online",
    description: "Sign up through our official website.",
    icon: "👤", // Replace with an actual image/icon if needed
  },
  {
    id: "02",
    title: "Mentor Matching",
    description:
      "Get paired with an experienced professional in your field of interest.",
    icon: "🔍",
  },
  {
    id: "03",
    title: "Attend Meetups",
    description:
      "Participate in structured mentorship sessions and discussions.",
    icon: "📅",
  },
  {
    id: "04",
    title: "Expand Your Network",
    description:
      "Build meaningful professional relationships with industry leaders.",
    icon: "🌐",
  },
];

const HowItWorks = () => {
  return (
    <section className="how-it-works-container">
      <h2 className="how-it-works-heading">How It Works</h2>
      <div className="how-it-works-grid">
        {steps.map((step, index) => (
          <div key={index} className="how-it-works-card">
            <span className="step-number">{step.id}</span>
            <div className="icon">{step.icon}</div>
            <h3>{step.title}</h3>
            <p>{step.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HowItWorks;
